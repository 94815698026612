<template>
    <div>
        <v-container fluid class="mt-lg-10">
            <v-card>
                <v-app-bar dark color="primary">
                    <v-toolbar-title>{{$t('msg.table_heading_export')}}</v-toolbar-title>
                </v-app-bar>
                <v-card-title></v-card-title>
                <v-row dense>
                    <v-col>
                        <v-data-table :headers="headers" :items="itemsWithIndex" :disable-sort="true" :hide-default-footer="true">
                            <template v-slot:item.filters_applied="{ item }">
                                <v-icon small class="mr-2" @click="viewAppliedFilter(item)">
                                    $vuetify.icons.values.mdiInformation
                                </v-icon>
                            </template>
                            <template v-slot:item.download="{ item }">
                    <span v-if="item.file_available">
                      <span v-if="checkArray(item.id)">
                        <v-icon class="mr-2" color="green" @click.native="exportExcel(item.id)">
                          $vuetify.icons.values.mdiFileDownloadOutline
                        </v-icon>
                      </span>
                      <span v-else>
                        <v-progress-circular indeterminate :size="20" color="#3c8dbc"/>
                      </span>
                    </span>
                                <span v-else>
                      <v-progress-circular indeterminate :size="20" color="#3c8dbc"/>
                    </span>
                            </template>
                            <template v-slot:item.status="{ item }">
                    <span v-if="item.file_available">
                      <span>{{ $t('msg.ready') }}</span>
                    </span>
                                <span v-else>
                      <span>{{ $t('msg.processing') }}</span>
                    </span>
                            </template>
                        </v-data-table>
                        <v-dialog max-width="450" persistent v-model="showAppliedFilters">
                            <v-card>
                                <v-card-title class="headline">{{ $t('msg.filters_applied') }}</v-card-title>
                                <v-card-text>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                            <tr>
                                                <td>{{ $t('msg.programme') }}</td>
                                                <td>:</td>
                                                <td v-if="!clickedItem.filters_applied.scheme">{{ $t('msg.all_programmes') }}</td>
                                                <td v-else>
                                                    <span>{{clickedItem.filters_applied.scheme}}</span>
                                                </td>
                                            </tr>
<!--                                            <tr>
                                                <td>{{ $t('msg.financing_amount_range') }}</td>
                                                <td>:</td>
                                                <td v-if="!clickedItem.filters_applied.amount">{{ $t('msg.all_financing_amount_ranges') }}</td>
                                                <td v-else>
                                                    <span>{{clickedItem.filters_applied.amount}}</span>
                                                </td>
                                            </tr>-->
                                            <tr>
                                                <td>{{ $t('msg.gfi_status') }}</td>
                                                <td>:</td>
                                                <td v-if="!clickedItem.filters_applied.status">{{ $t('msg.all_gfi_status') }}</td>
                                                <td v-else>
                                                    <span>{{clickedItem.filters_applied.status}}</span>
                                                </td>
                                            </tr>
<!--                                            <tr>
                                                <td>{{ $t('msg.financing_application_status') }}</td>
                                                <td>:</td>
                                                <td v-if="!clickedItem.filters_applied.application_status">{{ $t('msg.all_financing_application_status') }}</td>
                                                <td v-else>
                                                    <span>{{clickedItem.filters_applied.application_status}}</span>
                                                </td>
                                            </tr>-->
<!--                                            <tr>
                                                <td>{{ $t('msg.region') }}</td>
                                                <td>:</td>
                                                <td v-if="!clickedItem.filters_applied.region">{{ $t('msg.all_regions') }}</td>
                                                <td v-else>
                                                    <span>{{clickedItem.filters_applied.region}}</span>
                                                </td>
                                            </tr>-->
                                            <tr>
                                              <td>{{ $t('msg.branch') }}</td>
                                              <td>:</td>
                                              <td v-if="!clickedItem.filters_applied.branch">{{ $t('msg.all_branches') }}</td>
                                              <td v-else>
                                                <span>{{clickedItem.filters_applied.branch}}</span>
                                              </td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('msg.start_date') }}</td>
                                                <td>:</td>
                                                <td v-if="!clickedItem.filters_applied.start_date">-</td>
                                                <td v-else>
                                                    <span>{{clickedItem.filters_applied.start_date}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('msg.end_date') }}</td>
                                                <td>:</td>
                                                <td v-if="!clickedItem.filters_applied.end_date">-</td>
                                                <td v-else>
                                                    <span>{{clickedItem.filters_applied.end_date}}</span>
                                                </td>
                                            </tr>
                                            <tr v-show="clickedItem.filters_applied.search_text">
                                                <td>{{ $t('msg.searched_by') }}</td>
                                                <td>:</td>
                                                <td>
                                                    <span>{{clickedItem.filters_applied.search_text}}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" class="mr-4" dark @click="showAppliedFilters=false" right>Close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-card class="dashboard-datatable-footer dashboardFooterBottomMargin">
                    <v-card-actions class="dashboard-datatable-footer-actions">
                      {{ $t('msg.note_footer') }}
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-container>
        <v-snackbar v-model="reportDownloadError" :bottom="true" dark color="#3c8dbc" :right="true" :timeout="6000"
                    :vertical="true">
            <span v-html="$t('msg.export_unavailable')"></span>
            <v-btn dark text @click="reportDownloadError = false" v-html="$t('msg.close_label')"></v-btn>
        </v-snackbar>
    </div>
</template>
<script>
    import {dataServices , downloadFileService} from "../../../services";
    import fileSaver from 'file-saver'

    export default {
        name: 'ExportHistory',
        data () {
            return {
                overlay: false,
                clickedItem: {},
                showAppliedFilters: false,
                statusDropDown: [],
                repeater: null,
                requestDataArray: [''],
                tableBody: [],
                date: new Date().toJSON(),
                loadingDataTable: false,
                reportDownloadError: false,
                dialog: false,
                table: {
                    tableData: [],
                    totalLength: 0
                },
                headers: [
                    { text: this.$t('msg.table_header_no'), align: 'center', value: 'index' },
                    { text: this.$t('msg.table_header_date'), align: 'center', value: 'date_requested_on' },
                    { text: this.$t('msg.table_header_filter_applied'), align: 'center', sortable: false, value: 'filters_applied' },
                    { text: this.$t('msg.table_header_status'), align: 'center', sortable: false, value: 'status' },
                    { text: this.$t('msg.table_header_download'), align: 'center', sortable: false, value: 'download' }
                ]
            }
        },
        methods: {
            exportExcel: function (id) {
                this.reportDownloadError = false
                let loaderString = id
                if (this.requestDataArray.indexOf(loaderString) < 0) {
                    this.requestDataArray.push(loaderString)
                }
                downloadFileService.downloadSavedExcel({ id: id })
                    .then(response => {
                        if (response) {
                            fileSaver.saveAs(response, 'excel_export.xlsx')
                        }
                        this.requestDataArray.splice(this.requestDataArray.indexOf(loaderString), 1)
                        this.loadingDataTable = false
                    }).catch(error => {
                    this.loadingDataTable = false
                    this.requestDataArray.splice(this.requestDataArray.indexOf(loaderString), 1)
                    this.reportDownloadError = true
                    console.log('loggin response ' + error)
                })
            },
            checkArray: function (id) {
                return this.requestDataArray.indexOf(id) < 0
            },
            viewAppliedFilter (item) {
                this.showAppliedFilters = true
                this.clickedItem = item
            },
            getExportHistory: function () {
                this.overlay = true
                clearInterval(this.repeater)
                dataServices.fetchExportHistory()
                    .then(response => {
                      console.log(response);
                        if (response.dataResponse) {
                            if (response.dataResponse.tableData) {
                                this.tableBody = response.dataResponse.tableData
                                for (let i = 0; i < this.tableBody.length; i++) {
                                    if (!this.tableBody[i].file_available) {
                                        this.repeater = setInterval(() => {
                                            this.getExportHistory()
                                        }, 5000)
                                    }
                                }
                            }
                        }
                        this.overlay = false
                    }).catch(error => {
                    this.overlay = false
                    console.log('loggin response' + error)
                })
            }
        },
        mounted () {
            this.getExportHistory()
        },
        computed: {
            itemsWithIndex: function () {
                return this.tableBody.map(
                    (item, index) => ({
                        ...item,
                        index: index + 1
                    }))
            }
        },
        beforeDestroy () {
            clearInterval(this.repeater)
        }
    }
</script>

<style scoped>
    .v-breadcrumbs {
        padding: 7px 0px 0px 0px !important;
    }
</style>
<style>
    .v-input input {
        cursor: pointer !important;
    }

    .v-input__slot {
        cursor: default !important;
    }
</style>
